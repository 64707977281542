@import 'fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer base {
  :root {
    --primary: theme('colors.primary');
    --secondary: theme('colors.secondary');
    /* Color RGB del texto default */
    --text-body: 44 40 47;
    /* Texto de soporte o secundario */
    --text-support: 102 101 116;
    /* Color del background principal (body) */
    --bg-main: 255 255 255;
    /* Color del background de acento */
    --bg-accent: 239 244 247; /* neutral 100 RGB */
    /* Color de los bordes */
    --line: 213 213 223; /* neutral-100 RGB */

    @media (prefers-color-scheme: dark) {
      --text-body: 255 255 255;
      --text-support: 173 175 187;
      --bg-main: 29 27 32; /* neutral-900 RGB */
      --bg-accent: 44 40 47; /* neutral-800 RGB */
      --line: 130 134 150; /* neutral-500 RGB */
    }
    --billboard-cols: 3;
    @apply md:[--billboard-cols:4] xl:[--billboard-cols:5];
  }

  body {
    @apply h-full w-full overflow-x-hidden bg-main text-body;
  }
}

.dev:after {
  @apply fixed bottom-0 right-0 bg-black/50 p-2 text-xs text-white content-['xs'] sm:content-['sm'] md:content-['md'] lg:content-['lg'] xl:content-['xl'];
}

@layer components {
  .main {
    @apply flex-1 overflow-x-hidden;
  }

  .link {
    @apply text-primary hover:underline;
  }

  .input {
    @apply form-input rounded border-line bg-main transition-all duration-300
    placeholder:text-neutral-500 focus:border-neutral-600 focus:shadow-secondary focus:ring-neutral-600;
  }

  .billboard-grid {
    @apply grid grid-flow-row-dense grid-cols-3 gap-x-4 gap-y-2 md:grid-cols-4 md:gap-x-6 lg:grid-cols-5 lg:gap-x-8;
  }

  .bannerGradient {
    @apply absolute inset-x-0 bottom-0 h-[80px] bg-gradient-to-t from-white to-white/0 md:h-[130px];
    @apply pointer-events-none dark:from-neutral-900 dark:to-neutral-900/0;
  }

  .split {
    @apply flex flex-row items-center justify-between;
  }

  .hero {
    @apply mb-12 mt-10 space-y-2 text-center lg:mb-16 lg:mt-14;
  }

  /* .bigTitle {
    @apply h2 mb-2 md:h1 md:mb-auto;
  } */
}

.citi-notification {
  @apply pointer-events-none fixed z-[1000];

  &-notice {
    @apply relative mx-4 my-5 block w-full max-w-[calc(100%_-_32px)] rounded-xl bg-main py-4 pl-4 pr-7 shadow-xl md:w-[400px];

    &-closable {
      padding-right: 20px;
    }

    &-close {
      @apply pointer-events-auto absolute right-1 top-1 flex h-[24px]
      w-[24px] cursor-pointer items-center justify-center rounded-full bg-black text-base
      text-white outline-none transition-opacity;

      &-x {
        @apply flex items-center justify-center;
      }

      &:hover {
        opacity: 0.6;
        filter: alpha(opacity=60);
        text-decoration: none;
      }
    }
  }

  &-top &-notice,
  &-bottom &-notice {
    @apply left-1/2 -translate-x-1/2;
  }

  &-topLeft &-notice {
    @apply left-4 md:left-6;
  }

  &-topRight &-notice {
    @apply right-0 md:right-0;
  }

  &-fade-appear,
  &-fade-enter {
    opacity: 0;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
    animation-play-state: paused;
  }

  &-fade-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
    animation-play-state: paused;
  }

  &-fade-appear&-fade-appear-active,
  &-fade-enter&-fade-enter-active {
    animation-name: rcNotificationFadeIn;
    animation-play-state: running;
  }

  &-fade-leave&-fade-leave-active {
    animation-name: rcDialogFadeOut;
    animation-play-state: running;
  }
}

@keyframes rcNotificationFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rcDialogFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
